import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["tsa_container", "news_container", "resources_container", "videos_container", "graphs_container", "tsa_expand_button", "tsa_close_button"];

  expandTsasContainer(){
    this.tsa_containerTarget.classList.add("col-span-5");
    this.tsa_containerTarget.classList.remove("col-span-3");

    this.news_containerTarget.classList.add("hidden");
    this.resources_containerTarget.classList.add("hidden");

    this.tsa_expand_buttonTarget.classList.add("hidden");
    this.tsa_close_buttonTarget.classList.remove("hidden");
  }

  shrinkTsasContainer(){
    this.tsa_containerTarget.classList.remove("col-span-5");
    this.tsa_containerTarget.classList.add("col-span-3");

    this.news_containerTarget.classList.remove("hidden");
    this.resources_containerTarget.classList.remove("hidden");

    this.tsa_expand_buttonTarget.classList.remove("hidden");
    this.tsa_close_buttonTarget.classList.add("hidden");
  }
}
